<template>
    <v-list>
        <v-list-item>
            <v-list-item-avatar class="my-auto">
                <v-img :src="productLogoURL(item.productID)" class="my-auto">
                    <template v-slot:placeholder><v-icon size="40">mdi-cube-outline</v-icon></template>
                </v-img>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    <slot v-bind:item="item"></slot>
                </v-list-item-title>
                <v-list-item-title>
                    <v-text-field
                        dense
                        :disabled="!isEditing"
                        hide-details
                        @input="update"
                        prefix="$"
                        style="max-width: 120px;"
                        suffix="/ Unit"
                        type="number"
                        v-model.number="item.valuePerRateFactor" />
                </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="isEditing">
                <BT-Btn v-if="showDelete" icon="mdi-delete" @click="$emit('delete')" />
                <BT-Btn icon="mdi-plus-circle" @click="addIncrement" />
            </v-list-item-action>
        </v-list-item>
        <v-list-item v-for="(incItem, index) in item.valueIncrements" :key="index">
            <v-list-item-content>
                <v-list-item-title>
                    <v-row no-gutters>
                        <v-col cols="5">
                            <v-text-field
                                dense
                                :disabled="!isEditing"
                                hide-details
                                @input="update"
                                prefix=">="
                                :suffix="incItem.unitCountTrigger > 1 ? 'units is' : 'unit is'"
                                type="number"
                                v-model.number="incItem.unitCountTrigger" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                class="ml-4"
                                dense
                                :disabled="!isEditing"
                                hide-details
                                @input="update"
                                prefix="$"
                                suffix="/ Unit"
                                type="number"
                                v-model.number="incItem.unitValue" />
                                <!-- <template v-slot:append>
                                    <v-btn
                                        v-if="isEditing"
                                        small
                                        title="remove increment" 
                                        dense 
                                        icon 
                                        @click="removeIncrement(index)">
                                        <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                            </v-text-field> -->
                        </v-col>
                        <v-col cols="1" class="text-right">
                            <BT-Btn class="error--text" icon="mdi-delete" @click="removeIncrement(index)" small />
                        </v-col>
                    </v-row>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
    name: 'Price-Tier-Item-Blade',
    props: {
        isEditing: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: null
        },
        showDelete: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        maxIncrement() {
            var max = 0;
                for (var i = 0; i < this.item.valueIncrements.length; i++) {
                    if (this.item.valueIncrements[i].unitCountTrigger > max) {
                        max = this.item.valueIncrements[i].unitCountTrigger;
                    }
                }
                return +max + +1;
        }
    },
    methods: {
        addIncrement() {
            this.item.valueIncrements.push({
                unitCountTrigger: this.maxIncrement,
                unitValue: 0
            });

            this.item.valueIncrements.sort(function (b, a) {
                if (a.unitCountTrigger > b.unitCountTrigger) {
                    return -1;
                }
                if (b.unitCountTrigger > a.unitCountTrigger) {
                    return 1;
                }
                return 0;
            });

            this.update();
        },
        removeIncrement(index) {
            this.item.valueIncrements.splice(index, 1);
            this.update();
        },
        update() {
            if (this.item != null && this.isLengthyArray(this.item.valueIncrements)) {
                this.item.valueIncrements.forEach(inc => {
                    if (Number.isNaN(inc.unitCountTrigger)) {
                        inc.unitCountTrigger = 0;
                    }
                    if (Number.isNaN(inc.unitValue)) {
                        inc.unitValue = 0;
                    }
                });
            }
            // for (var i = 0; i < this.item.valueIncrements; i++) {
            //     var inc = this.item.valueIncrements[i];
            //     if (!inc.unitCountTrigger || inc.unitCountTrigger.length === 0) {
            //         inc.unitCountTrigger = 0;
            //     }
            //     if (!inc.unitValue || inc.unitCountTrigger.length === 0) {
            //         inc.unitValue = 0;
            //     }
            // }

            if (Number.isNaN(this.item.valuePerRateFactor)) {
                this.item.valuePerRateFactor = 0;
            }

            //this.item.valueIncrements = this.valueIncrements;

            //this.$emit('input', this.item);
            // console.log('emitting');
            // console.log(this.item);
            // this.$emit('change', this.item);
        }
    }
}
</script>